import React, { useState } from "react"
import { Link } from "gatsby"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ogImage from "../../../images/seo/fabs/fabs.png"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Preview
// Rounded
import FabAllRoundedBasicPreview from "../../../library/components/fab/react/_preview/rounded/basic"
import FabAllRoundedTooltipsPreview from "../../../library/components/fab/react/_preview/rounded/tooltips"
import FabAllRoundedInsideTextPreview from "../../../library/components/fab/react/_preview/rounded/inside_text"
import FabAllRoundedOutsideTextPreview from "../../../library/components/fab/react/_preview/rounded/outside_text"
import FabAllRoundedHorizontalPreview from "../../../library/components/fab/react/_preview/rounded/horizontal"
// Rounded full
import FabAllRoundedFullBasicPreview from "../../../library/components/fab/react/_preview/rounded-full/basic"
import FabAllRoundedFullTooltipsPreview from "../../../library/components/fab/react/_preview/rounded-full/tooltips"
import FabAllRoundedFullInsideTextPreview from "../../../library/components/fab/react/_preview/rounded-full/inside_text"
import FabAllRoundedFullOutsideTextPreview from "../../../library/components/fab/react/_preview/rounded-full/outside_text"
import FabAllRoundedFullHorizontalPreview from "../../../library/components/fab/react/_preview/rounded-full/horizontal"

import FabsHeroComponent from "../../../library/components/fab/react/_preview/FabsHeroComponent"

// Rounded
//Basic
const FabRoundedBasicLeftJsx = require("!!raw-loader!../../../library/components/fab/react/rounded/basic-left.jsx")
const FabRoundedBasicLeftHTML = require("!!raw-loader!../../../library/components/fab/html/rounded/basic-left.html")

const FabRoundedBasicRightJsx = require("!!raw-loader!../../../library/components/fab/react/rounded/basic-right.jsx")
const FabRoundedBasicRightHTML = require("!!raw-loader!../../../library/components/fab/html/rounded/basic-right.html")

//Tooltips
const FabRoundedTooltipsLeftJsx = require("!!raw-loader!../../../library/components/fab/react/rounded/tooltips-left.jsx")
const FabRoundedTooltipsLeftHTML = require("!!raw-loader!../../../library/components/fab/html/rounded/tooltips-left.html")

const FabRoundedTooltipsRightJsx = require("!!raw-loader!../../../library/components/fab/react/rounded/tooltips-right.jsx")
const FabRoundedTooltipsRightHTML = require("!!raw-loader!../../../library/components/fab/html/rounded/tooltips-right.html")

//Inside text
const FabRoundedInsideTextLeftJsx = require("!!raw-loader!../../../library/components/fab/react/rounded/inside_text-left.jsx")
const FabRoundedInsideTextLeftHTML = require("!!raw-loader!../../../library/components/fab/html/rounded/inside_text-left.html")

const FabRoundedInsideTextRightJsx = require("!!raw-loader!../../../library/components/fab/react/rounded/inside_text-right.jsx")
const FabRoundedInsideTextRightHTML = require("!!raw-loader!../../../library/components/fab/html/rounded/inside_text-right.html")

//Outside text
const FabRoundedOutsideTextLeftJsx = require("!!raw-loader!../../../library/components/fab/react/rounded/outside_text-left.jsx")
const FabRoundedOutsideTextLeftHTML = require("!!raw-loader!../../../library/components/fab/html/rounded/outside_text-left.html")

const FabRoundedOutsideTextRightJsx = require("!!raw-loader!../../../library/components/fab/react/rounded/outside_text-right.jsx")
const FabRoundedOutsideTextRightHTML = require("!!raw-loader!../../../library/components/fab/html/rounded/outside_text-right.html")

//Horizontal
const FabRoundedHorizontalLeftJsx = require("!!raw-loader!../../../library/components/fab/react/rounded/horizontal-left.jsx")
const FabRoundedHorizontalLeftHTML = require("!!raw-loader!../../../library/components/fab/html/rounded/horizontal-left.html")

const FabRoundedHorizontalRightJsx = require("!!raw-loader!../../../library/components/fab/react/rounded/horizontal-right.jsx")
const FabRoundedHorizontalRightHTML = require("!!raw-loader!../../../library/components/fab/html/rounded/horizontal-right.html")

// Rounded Full
//Basic
const FabRoundedFullBasicLeftJsx = require("!!raw-loader!../../../library/components/fab/react/rounded-full/basic-left.jsx")
const FabRoundedFullBasicLeftHTML = require("!!raw-loader!../../../library/components/fab/html/rounded-full/basic-left.html")

const FabRoundedFullBasicRightJsx = require("!!raw-loader!../../../library/components/fab/react/rounded-full/basic-right.jsx")
const FabRoundedFullBasicRightHTML = require("!!raw-loader!../../../library/components/fab/html/rounded-full/basic-right.html")

//Tooltips
const FabRoundedFullTooltipsLeftJsx = require("!!raw-loader!../../../library/components/fab/react/rounded-full/tooltips-left.jsx")
const FabRoundedFullTooltipsLeftHTML = require("!!raw-loader!../../../library/components/fab/html/rounded-full/tooltips-left.html")

const FabRoundedFullTooltipsRightJsx = require("!!raw-loader!../../../library/components/fab/react/rounded-full/tooltips-right.jsx")
const FabRoundedFullTooltipsRightHTML = require("!!raw-loader!../../../library/components/fab/html/rounded-full/tooltips-right.html")

//Inside text
const FabRoundedFullInsideTextLeftJsx = require("!!raw-loader!../../../library/components/fab/react/rounded-full/inside_text-left.jsx")
const FabRoundedFullInsideTextLeftHTML = require("!!raw-loader!../../../library/components/fab/html/rounded-full/inside_text-left.html")

const FabRoundedFullInsideTextRightJsx = require("!!raw-loader!../../../library/components/fab/react/rounded-full/inside_text-right.jsx")
const FabRoundedFullInsideTextRightHTML = require("!!raw-loader!../../../library/components/fab/html/rounded-full/inside_text-right.html")

//Outside text
const FabRoundedFullOutsideTextLeftJsx = require("!!raw-loader!../../../library/components/fab/react/rounded-full/outside_text-left.jsx")
const FabRoundedFullOutsideTextLeftHTML = require("!!raw-loader!../../../library/components/fab/html/rounded-full/outside_text-left.html")

const FabRoundedFullOutsideTextRightJsx = require("!!raw-loader!../../../library/components/fab/react/rounded-full/outside_text-right.jsx")
const FabRoundedFullOutsideTextRightHTML = require("!!raw-loader!../../../library/components/fab/html/rounded-full/outside_text-right.html")

//Horizontal
const FabRoundedFullHorizontalLeftJsx = require("!!raw-loader!../../../library/components/fab/react/rounded-full/horizontal-left.jsx")
const FabRoundedFullHorizontalLeftHTML = require("!!raw-loader!../../../library/components/fab/html/rounded-full/horizontal-left.html")

const FabRoundedFullHorizontalRightJsx = require("!!raw-loader!../../../library/components/fab/react/rounded-full/horizontal-right.jsx")
const FabRoundedFullHorizontalRightHTML = require("!!raw-loader!../../../library/components/fab/html/rounded-full/horizontal-right.html")

export default function FabsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "BasicFab",
      title: "Basic floating action button",
      active_tab: 1,
    },
    {
      component_name: "TooltipsFab",
      title: "With tooltips",
      active_tab: 1,
    },
    {
      component_name: "LgInsideTextFab",
      title: "Inside text",
      active_tab: 1,
    },
    {
      component_name: "OutsideTextFab",
      title: "Outside text",
      active_tab: 1,
    },
    {
      component_name: "HorizontalFab",
      title: "Horizontal",
      active_tab: 1,
    },
    {
      component_name: "BasicFabFullyRounded",
      title: "Rounded full basic",
      active_tab: 1,
    },
    {
      component_name: "TooltipsFabFullyRounded",
      title: "Rounded full with tooltips",
      active_tab: 1,
    },
    {
      component_name: "LgInsideTextFabFullyRounded",
      title: "Rounded full inside text",
      active_tab: 1,
    },
    {
      component_name: "OutsideTextFabFullyRounded",
      title: "Rounded full outside text",
      active_tab: 1,
    },
    {
      component_name: "HorizontalFabFullyRounded",
      title: "Rounded full horizontal",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Floating Action Buttons (FAB) - WindUI Component Library"
        ogtitle="Tailwind CSS Floating Action Buttons (FAB) - WindUI Component Library"
        description="A Floating Action Button (FAB) component is a high-emphasis button that lets the user perform a primary action. Built with Tailwind CSS by WindUI."
        ogdescription="A Floating Action Button (FAB) component is a high-emphasis button that lets the user perform a primary action. Built with Tailwind CSS by WindUI."
        url="components/fabs/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Floating Action Buttons (FAB), Floating Action Buttons, FABs, FAB buttons, Floating Action Button components, FABs Components, WindUI, windui, wind ui"
        ogimage={ogImage}
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}

        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Floating Action Buttons</h1>
          <p>
            A Floating Action Button (FAB) is a high-emphasis button that lets
            the user perform a primary action.
          </p>

          {/* Hero Preview Section */}
          <div className="relative isolate flex h-96 w-full justify-between overflow-hidden rounded-xl bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <FabsHeroComponent />
            </section>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="BasicFab">
            Basic floating action buttons, left and right sided
          </h3>

          <PreviewBlock
            id="BasicFab"
            HtmlComponent={FabRoundedBasicLeftHTML.default}
            JsxComponent={FabRoundedBasicLeftJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="relative isolate flex h-96 w-full justify-between overflow-hidden rounded-xl bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
              <FabAllRoundedBasicPreview
                FabRoundedBasicLeft={
                  activeTabs[0].active_tab === 1
                    ? FabRoundedBasicLeftHTML.default
                    : FabRoundedBasicLeftJsx.default
                }
                FabRoundedBasicRight={
                  activeTabs[0].active_tab === 1
                    ? FabRoundedBasicRightHTML.default
                    : FabRoundedBasicRightJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="TooltipsFab">
            Floating action buttons with tooltips, left and right sided
          </h3>

          <PreviewBlock
            id="TooltipsFab"
            HtmlComponent={FabRoundedTooltipsLeftHTML.default}
            JsxComponent={FabRoundedTooltipsLeftJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="relative isolate flex h-96 w-full justify-between overflow-hidden rounded-xl bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
              <FabAllRoundedTooltipsPreview
                FabRoundedTooltipsLeft={
                  activeTabs[1].active_tab === 1
                    ? FabRoundedTooltipsLeftHTML.default
                    : FabRoundedTooltipsLeftJsx.default
                }
                FabRoundedTooltipsRight={
                  activeTabs[1].active_tab === 1
                    ? FabRoundedTooltipsRightHTML.default
                    : FabRoundedTooltipsRightJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="LgInsideTextFab">
            Xl floating action buttons with inside text, left and right sided
          </h3>

          <PreviewBlock
            id="LgInsideTextFab"
            HtmlComponent={FabRoundedInsideTextLeftHTML.default}
            JsxComponent={FabRoundedInsideTextLeftJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="relative isolate flex h-96 w-full justify-between overflow-hidden rounded-xl bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
              <FabAllRoundedInsideTextPreview
                FabRoundedInsideTextLeft={
                  activeTabs[2].active_tab === 1
                    ? FabRoundedInsideTextLeftHTML.default
                    : FabRoundedInsideTextLeftJsx.default
                }
                FabRoundedInsideTextRight={
                  activeTabs[2].active_tab === 1
                    ? FabRoundedInsideTextRightHTML.default
                    : FabRoundedInsideTextRightJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="OutsideTextFab">
            Floating action buttons with outside text, left and right sided
          </h3>

          <PreviewBlock
            id="OutsideTextFab"
            HtmlComponent={FabRoundedOutsideTextLeftHTML.default}
            JsxComponent={FabRoundedOutsideTextLeftJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="relative isolate flex h-96 w-full justify-between overflow-hidden rounded-xl bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
              <FabAllRoundedOutsideTextPreview
                FabRoundedOutsideTextLeft={
                  activeTabs[3].active_tab === 1
                    ? FabRoundedOutsideTextLeftHTML.default
                    : FabRoundedOutsideTextLeftJsx.default
                }
                FabRoundedOutsideTextRight={
                  activeTabs[3].active_tab === 1
                    ? FabRoundedOutsideTextRightHTML.default
                    : FabRoundedOutsideTextRightJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="HorizontalFab">
            Horizontal floating action buttons, left and right sided
          </h3>

          <PreviewBlock
            id="HorizontalFab"
            HtmlComponent={FabRoundedHorizontalLeftHTML.default}
            JsxComponent={FabRoundedHorizontalLeftJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="relative isolate flex h-96 w-full justify-between overflow-hidden rounded-xl bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
              <FabAllRoundedHorizontalPreview
                FabRoundedHorizontalLeft={
                  activeTabs[4].active_tab === 1
                    ? FabRoundedHorizontalLeftHTML.default
                    : FabRoundedHorizontalLeftJsx.default
                }
                FabRoundedHorizontalRight={
                  activeTabs[4].active_tab === 1
                    ? FabRoundedHorizontalRightHTML.default
                    : FabRoundedHorizontalRightJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="BasicFabFullyRounded">
            Rounded full basic floating action buttons, left and right sided
          </h3>

          <PreviewBlock
            id="BasicFabFullyRounded"
            HtmlComponent={FabRoundedFullBasicLeftHTML.default}
            JsxComponent={FabRoundedFullBasicLeftJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="relative isolate flex h-96 w-full justify-between overflow-hidden rounded-xl bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
              <FabAllRoundedFullBasicPreview
                FabRoundedFullBasicLeft={
                  activeTabs[5].active_tab === 1
                    ? FabRoundedFullBasicLeftHTML.default
                    : FabRoundedFullBasicLeftJsx.default
                }
                FabRoundedFullBasicRight={
                  activeTabs[5].active_tab === 1
                    ? FabRoundedFullBasicRightHTML.default
                    : FabRoundedFullBasicRightJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="TooltipsFabFullyRounded">
            Rounded full floating action buttons with tooltips, left and right
            sided
          </h3>

          <PreviewBlock
            id="TooltipsFabFullyRounded"
            HtmlComponent={FabRoundedFullTooltipsLeftHTML.default}
            JsxComponent={FabRoundedFullTooltipsLeftJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="relative isolate flex h-96 w-full justify-between overflow-hidden rounded-xl bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
              <FabAllRoundedFullTooltipsPreview
                FabRoundedFullTooltipsLeft={
                  activeTabs[6].active_tab === 1
                    ? FabRoundedFullTooltipsLeftHTML.default
                    : FabRoundedFullTooltipsLeftJsx.default
                }
                FabRoundedFullTooltipsRight={
                  activeTabs[6].active_tab === 1
                    ? FabRoundedFullTooltipsRightHTML.default
                    : FabRoundedFullTooltipsRightJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="LgInsideTextFabFullyRounded">
            Rounded full xl floating action buttons with inside text left and
            right sided
          </h3>

          <PreviewBlock
            id="LgInsideTextFabFullyRounded"
            HtmlComponent={FabRoundedFullInsideTextLeftHTML.default}
            JsxComponent={FabRoundedFullInsideTextLeftJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="relative isolate flex h-96 w-full justify-between overflow-hidden rounded-xl bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
              <FabAllRoundedFullInsideTextPreview
                FabRoundedFullInsideTextLeft={
                  activeTabs[7].active_tab === 1
                    ? FabRoundedFullInsideTextLeftHTML.default
                    : FabRoundedFullInsideTextLeftJsx.default
                }
                FabRoundedFullInsideTextRight={
                  activeTabs[7].active_tab === 1
                    ? FabRoundedFullInsideTextRightHTML.default
                    : FabRoundedFullInsideTextRightJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="OutsideTextFabFullyRounded">
            Rounded full floating action buttons with outside text left and
            right sided
          </h3>

          <PreviewBlock
            id="OutsideTextFabFullyRounded"
            HtmlComponent={FabRoundedFullOutsideTextLeftHTML.default}
            JsxComponent={FabRoundedFullOutsideTextLeftJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="relative isolate flex h-96 w-full justify-between overflow-hidden rounded-xl bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
              <FabAllRoundedFullOutsideTextPreview
                FabRoundedFullOutsideTextLeft={
                  activeTabs[8].active_tab === 1
                    ? FabRoundedFullOutsideTextLeftHTML.default
                    : FabRoundedFullOutsideTextLeftJsx.default
                }
                FabRoundedFullOutsideTextRight={
                  activeTabs[8].active_tab === 1
                    ? FabRoundedFullOutsideTextRightHTML.default
                    : FabRoundedFullOutsideTextRightJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="HorizontalFabFullyRounded">
            Rounded full horizontal floating action buttons, left and right
            sided
          </h3>

          <PreviewBlock
            id="HorizontalFabFullyRounded"
            HtmlComponent={FabRoundedFullHorizontalLeftHTML.default}
            JsxComponent={FabRoundedFullHorizontalLeftJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="relative isolate flex h-96 w-full justify-between overflow-hidden rounded-xl bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
              <FabAllRoundedFullHorizontalPreview
                FabRoundedFullHorizontalLeft={
                  activeTabs[9].active_tab === 1
                    ? FabRoundedFullHorizontalLeftHTML.default
                    : FabRoundedFullHorizontalLeftJsx.default
                }
                FabRoundedFullHorizontalRight={
                  activeTabs[9].active_tab === 1
                    ? FabRoundedFullHorizontalRightHTML.default
                    : FabRoundedFullHorizontalRightJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Use a Floating Action Button (FAB) to highlight the key action
            within a screen. The FAB stands out appearing above other content.
            Reserve the FAB exclusively for showcasing the primary action of a
            screen.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>

          <p>
            Floating Action Buttons can consist of the following elements text,
            icons and even tooltips. Variations may include the following
            characteristics:
          </p>
          <ul>
            <li>
              <strong>Labels: </strong> 0.875rem (14px) font size.
            </li>
            <li>
              <strong>Buttons: </strong> XLarge Buttons: 3.5rem height (56px)
              and 1.75rem (28px) horizontal padding. Icons on large buttons are
              1.25rem (20px) wide and tall and have a 0.5rem (8px) distance from
              the text. Or large size buttons (see{" "}
              <Link to="/components/buttons">buttons</Link> ).
            </li>
            <li>
              <strong>Tooltips: </strong> small size tooltip (see{" "}
              <Link to="/components/tooltips">tooltips</Link> ).
            </li>
          </ul>
          <p>Floating Action Buttons come in 2 different positions:</p>
          <ul>
            <li>
              <strong>Left: </strong> positioned 2rem (32px) from the bottom and
              2rem (32px) from the left of the screen.
            </li>
            <li>
              <strong>Right: </strong> positioned 2rem (32px) from the bottom
              and 2rem (32px) from the right of the screen.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <ul>
            <li>
              The Floating Action Button (FAB) does not require any additional
              accessibility settings. For the included{" "}
              <code>&lt;button&gt;</code> check{" "}
              <Link to="/components/buttons">buttons</Link>.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
